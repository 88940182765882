import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getAoiBuffer } from "../../utils/requests";
import ButtonCustom from "../ui/button";
import "./style.scss";

const AoiBuffer = (props: any) => {
  const { t } = useTranslation();

  const { features, setFeatures, setSnackbarProps, setOpenSnackbar } = props

  const [openBufferPopup, setOpenBufferPopup] = useState(false);

  const handleClickSearch = () => {
    setOpenBufferPopup(!openBufferPopup);
  };

  const handleSubmitBuffer = async (event: any) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData).entries());

    const bufferSize = formJson.bufferSize.toString();

    await getAoiBuffer(features, bufferSize).then(
      (bufferResponse) => {
        if (bufferResponse.status === 200) {
          const newAoi = bufferResponse.data.buffered_geojson
          setFeatures(newAoi)
        }
      }
    ).catch((err) => {
      setSnackbarProps({ message: err.response.data.message, severity: 'error' })
      setOpenSnackbar(true)
    });
    setOpenBufferPopup(false)
  }

  const handleCancelBuffer = () => {
    setOpenBufferPopup(false)
  }

  return (
    <>
      <div>
        <ButtonCustom
          className={`${features.features.length > 0 ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
          variant="contained"
          label={t('newProject.buffer')}
          handleClick={handleClickSearch}
          tooltip={t('newProject.buffer')}
        />
      </div>
      <Dialog
        open={openBufferPopup}
        onClose={handleCancelBuffer}
        PaperProps={{
          component: 'form',
          onSubmit: (event: any) => {
            handleSubmitBuffer(event)
          },
        }}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('newProject.bufferTitle')}:
          </Typography>
          <TextField
            id="buffer-size"
            label={t('newProject.bufferInputLabel')}
            name="bufferSize"
            autoFocus
            required
            type="number"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelBuffer}>{t('common.cancelButton')}</Button>
          <Button type='submit'>{t('common.continueButton')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AoiBuffer;
