import { FileCopyOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InvertColorsOutlinedIcon from "@mui/icons-material/InvertColorsOutlined";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "../../theme/styles";
import StyleCard from "../styleCard";
import CheckboxCustom from "../ui/checkbox";
import DownloadDataset from "./downloadDataset";
import "./style.scss";

function MenuMap(props: any) {
  const {
    openPanel,
    listDataset,
    handleChangeCheckLayer,
    dataResultsMap,
    handleChangeSelect,
    optionSelectKPI,
    radiationMinMaxValue,
    altitudeMinMaxValue,
    handleChangeTransparency,
    handleChangeTransparencyXyz,
    area,
    setSnackbarProps,
    setOpenSnackbar
  } = props;
  const classes = useStyles();

  const { t } = useTranslation()

  const [value, setValue] = useState("0");
  const [xyzLayerActive, setXyzLayerActive] = useState<{ [id: string]: boolean }>({});

  const toggleXyzLayer = (id: string, checked: boolean | undefined = undefined) => {
    setXyzLayerActive((prevState) => {
      return {
        ...prevState,
        [id]: checked === undefined ? !prevState[id] : checked
      };
    });
  };

  const handleChangeCheckDataset = (e: React.ChangeEvent<HTMLInputElement>, id_project: string, id_dataset: string, name_attribute: string, transparency: number) => {
    const checked = e.target.checked
    toggleXyzLayer(id_dataset, checked)
    handleChangeCheckLayer(e, id_project, id_dataset, name_attribute, transparency)
  }

  const token = localStorage.getItem("token");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleCopyPassword = () => {
    if (token) {
      navigator.clipboard.writeText(token);
    }
  };

  const formatValue = (value: any) => {
    const parsed = parseFloat(value)
    const valueInKilometers = parsed / 10 ** 6

    if (parseFloat(valueInKilometers.toFixed(3)) === 0) {
      const formated = parsed.toFixed(3).replace(".", ",").replace(/\B(?=(\d{5})+(?!\d))/g, ".");
      return <Typography>{formated} (m<sup>2</sup>)</Typography>;
    }
    else {
      const formated = valueInKilometers.toFixed(3).replace(".", ",").replace(/\B(?=(\d{5})+(?!\d))/g, ".");
      return <Typography>{formated} (km<sup>2</sup>)</Typography>;
    }
  };

  return (
    <>
      {openPanel && (
        <div className="dash">
          <Box className="panel-box">
            <Tabs
              value={value}
              onChange={handleChangeTab}
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Tab className="dashboard-tab" label={t('dashboardResults.legendTab')} value="0" />
              <Tab className="dashboard-tab" label={t('dashboardResults.resultsTab')} value="1" />
              <Tab className="dashboard-tab" label={t('dashboardResults.sharingTab')} value="2" />
            </Tabs>
          </Box>
          {value === "0" && (
            <div role="tabpanel">
              <Box sx={{ p: 3 }}>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                ></Typography>
                {Object.values(listDataset).map((pro: any) =>
                  pro.map(
                    (
                      {
                        name_asset,
                        name_attribute,
                        checked,
                        id_project,
                        project_name,
                        last_execution_date,
                        id_dataset,
                        state,
                        transparency,
                        url_image
                      }: any,
                      index: number
                    ) => {
                      return (
                        state === "Completed" &&
                        <>
                          <Accordion
                            className="accordionItem"
                            key={`${index}_accordion`}>
                            <AccordionSummary
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className={`accordionTitle ${classes.card_content}`}
                              sx={{
                                pointerEvents: "none",
                              }}
                              key={`${index}_accordion_summary`}
                            >
                              <Grid
                                container
                                rowSpacing={1}
                                sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <Grid item xs={2}>
                                  <CheckboxCustom
                                    handleChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      handleChangeCheckDataset(
                                        e,
                                        id_project,
                                        id_dataset,
                                        name_attribute,
                                        transparency,
                                      )
                                    }
                                    checked={checked}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    {`${name_asset} ${name_attribute}`}
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <div className={classes.card_icon}>
                                    <span onClick={(e: any) => { e.stopPropagation() }}>
                                      <Tooltip
                                        title={`${project_name} - ${last_execution_date}`}
                                        placement="top-end"
                                      >
                                        <InfoOutlinedIcon
                                          titleAccess={t('common.infoLayer')}
                                          sx={{
                                            pointerEvents: "auto",
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                    <InvertColorsOutlinedIcon
                                      titleAccess={t('common.symbologySettings')}
                                      sx={{
                                        pointerEvents: "auto",
                                      }}
                                    />

                                    {url_image ? (
                                      <MapOutlinedIcon
                                        titleAccess={t('common.toggleXYZLayer')}
                                        sx={{
                                          pointerEvents: checked ? "auto" : "none",
                                        }}
                                        className={!checked ? "xyz-disabled" : xyzLayerActive[id_dataset] ? "xyz-active" : "xyz-inactive"}
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                          if (checked) {
                                            handleChangeTransparencyXyz(
                                              e,
                                              id_project,
                                              id_dataset,
                                              url_image
                                            );
                                            toggleXyzLayer(id_dataset);
                                          }
                                        }}
                                      />
                                    ) : (
                                      <MapOutlinedIcon
                                        titleAccess={t('common.toggleXYZLayer')}
                                        sx={{
                                          pointerEvents: "auto",
                                          color: "lightgrey",
                                        }}
                                      />
                                    )}
                                    <DownloadDataset
                                      id_dataset={id_dataset}
                                      id_project={id_project}
                                      project_name={project_name}
                                      name_asset={name_asset}
                                      name_attribute={name_attribute}
                                      index={index}
                                      setSnackbarProps={setSnackbarProps}
                                      setOpenSnackbar={setOpenSnackbar} />
                                  </div>
                                </Grid>
                                <Grid item sx={{ pointerEvents: checked ? "auto" : "none", }}>
                                  <StyleCard
                                    type={name_attribute}
                                    radiationMinMaxValue={radiationMinMaxValue}
                                    altitudeMinMaxValue={altitudeMinMaxValue}
                                    checked={checked}
                                  />
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails key={`${index}_accordion_details`}>
                              <Box sx={{
                                marginLeft: 5,
                              }}>
                                <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.opacity')}</p>
                                <Slider
                                  style={{ width: "80%" }}
                                  size="small"
                                  valueLabelDisplay="auto"
                                  value={transparency}
                                  onChange={(
                                    e: any
                                  ) =>
                                    handleChangeTransparency(
                                      e,
                                      id_project,
                                      id_dataset,
                                      name_attribute
                                    )}
                                  marks={[
                                    {
                                      value: 0,
                                      label: "0%",
                                    },
                                    {
                                      value: 100,
                                      label: "100%",
                                    },
                                  ]}
                                />
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      );
                    }
                  )
                )}
              </Box>
            </div>
          )}
          {value === "1" && (
            <div role="tabpanel">
              <Box sx={{ p: 3 }}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 248 }}
                  className={`select_white ${classes.select}`}
                >
                  <InputLabel className="label_select">{t("dashboardResults.selectDataset")}</InputLabel>
                  <Select
                    className="kpi-select"
                    value={optionSelectKPI}
                    name="useCase"
                    onChange={handleChangeSelect}
                  >
                    {Object.values(listDataset).map((pro: any) =>
                      pro.map(
                        ({
                          name_asset,
                          name_attribute,
                          id_dataset,
                          id_project,
                          checked,
                        }: any) => {
                          return (
                            <MenuItem
                              value={`${id_dataset}:${id_project}`}
                            >{`${name_asset} ${name_attribute}`}</MenuItem>
                          );
                        }
                      )
                    )}
                  </Select>
                </FormControl>
                <div className={classes.divCardContent}>
                  <Card
                    sx={{ minWidth: "30%" }}
                    className={`${classes.card} ${classes.card50}`}
                  >
                    <CardContent className={classes.card_contentkpi}>
                      <Typography
                        sx={{ fontSize: 16 }}
                        gutterBottom
                        className="item-label"
                      >
                        <InputLabel className="result-label">{t('dashboardResults.resultsItems')}</InputLabel>
                      </Typography>
                      <div className={classes.card_kpi_items}>
                        <Typography variant="h5" gutterBottom component="div">
                          {dataResultsMap.count && optionSelectKPI
                            ? dataResultsMap.count
                            : "--"}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <Card sx={{ minWidth: "30%" }} className={classes.card}>
                  <CardContent className={classes.card_contentkpi}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <InputLabel className="result-label">{t('dashboardResults.resultsAverage')}</InputLabel>
                    </Typography>
                    <div className={classes.card_kpi}>
                      <Typography variant="h5" gutterBottom component="div">
                        {optionSelectKPI && dataResultsMap.average
                          ? dataResultsMap.average.toFixed(2)
                          : "--"}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: "30%" }} className={classes.card}>
                  <CardContent className={classes.card_contentkpi}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <InputLabel className="result-label">{t('dashboardResults.max')}</InputLabel>
                    </Typography>
                    <div className={classes.card_kpi}>
                      <Typography variant="h5" gutterBottom component="div">
                        {optionSelectKPI && dataResultsMap.maximum
                          ? dataResultsMap.maximum
                          : "--"}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: "30%" }} className={classes.card}>
                  <CardContent className={classes.card_contentkpi}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <InputLabel className="result-label">{t('dashboardResults.min')}</InputLabel>
                    </Typography>
                    <div className={classes.card_kpi}>
                      <Typography variant="h5" gutterBottom component="div">
                        {optionSelectKPI && dataResultsMap.minimum
                          ? dataResultsMap.minimum
                          : "--"}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: "30%" }} className={classes.card}>
                  <CardContent className={classes.card_contentkpi}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <InputLabel className="result-label">{t('dashboardResults.area')}</InputLabel>
                    </Typography>
                    <div className={classes.card_kpi}>
                      <Typography variant="h5" gutterBottom component="div">
                        {area ? formatValue(area) : "--"}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Box>
            </div>
          )}
          {value === "2" && (
            <div role="tabpanel">
              <Box sx={{ p: 3 }}>
                <TextField
                  label="Token"
                  disabled
                  value={token}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleCopyPassword}>
                        <FileCopyOutlined />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      color: "black",

                      "-webkit-text-fill-color": "black",
                    },
                  }}
                />
              </Box>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default MenuMap;
